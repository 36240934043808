import React, { useState } from "react";
import "./login.css";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AdsReportHeader from "../header/headerAds";

const Login = ({ onLogin }) => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("SalesExecutive");
  const [errors, setErrors] = useState({ userId: "", password: "" });
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const validateUserId = (value) => {
    const userIdRegex = /^[A-Za-z]+\d+$/;
    if (!value) {
      return "UserId is required.";
    }
    if (!userIdRegex.test(value)) {
      return "UserId must contain letters followed by digits.";
    }
    return "";
  };

  const validatePassword = (value) => {
    if (!value) {
      return "Password is required.";
    }
    if (value.length < 6) {
      return "Password must be at least 6 characters.";
    }
    return "";
  };

  const handleUserIdChange = (e) => {
    const value = e.target.value;
    setUserId(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      userId: validateUserId(value),
    }));
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: validatePassword(value),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userIdError = validateUserId(userId);
    const passwordError = validatePassword(password);

    if (userIdError || passwordError) {
      setErrors({ userId: userIdError, password: passwordError });
      return;
    }

    onLogin(userId, password, navigate);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  return (
    <div className="container">
      {<AdsReportHeader />}

      <div className="right-section">
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <label className="label">UserId</label>
            <input
              type="text"
              value={userId}
              onChange={handleUserIdChange}
              placeholder="Enter UserId"
              className={`input ${errors.userId ? "input-error" : ""}`}
            />
            {errors.userId && <span className="error">{errors.userId}</span>}
          </div>
          <div className="form-group">
            <label className="label">Password</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter Password"
              className={`input ${errors.password ? "input-error" : ""}`}
            />
            {errors.password && (
              <span className="error">{errors.password}</span>
            )}
          </div>
          <button
            type="submit"
            className="button"
            disabled={!!errors.userId || !!errors.password}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
