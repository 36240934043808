import React, { useEffect, useState } from "react";
import Navigator from "./Navigator";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

const App = () => {
  const [vh, setVh] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLandscape = useMediaQuery("(orientation: landscape)");

  return (
    <div
      className="app"
      style={{
        display: isMediumDown ? "flex" : "",
        flexDirection: isMediumDown ? "column-reverse" : "",
        height: isMediumDown ? (isLandscape ? "100vh" : `${vh}px`) : "",
        // backgroundColor: "#f1f3f6",
      }}
    >
      <Navigator />
    </div>
  );
};

export default App;
