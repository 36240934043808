import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import VoiceInput from "./voiceInput";
import { LiveAudioVisualizer } from "react-audio-visualize";
import ResponseTranslationDropdown from "./responseTranslation";

function MessageInput({
  handleSend,
  userLang,
  setUserLang,
  isLoading,
  setIsInputFocused,
}) {
  const [input, setInput] = useState("");
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);

  const handleVoiceInput = (transcript) => {
    handleSend(transcript);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend(input);
      setInput("");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isSmallDown ? "8px" : "10px",
        width: "100%",
      }}
    >
      <TextField
        placeholder={isRecording ? "Recording..." : "Type your message here"}
        onChange={(e) => setInput(e.target.value)}
        value={input}
        onKeyDown={handleKeyDown}
        InputProps={{
          onFocus: (e) => {
            e.preventDefault();
            setIsInputFocused(true);
          },
          onBlur: (e) => {
            e.preventDefault();
            setIsInputFocused(false);
          },
          startAdornment: (
            <InputAdornment position="start">
              <ResponseTranslationDropdown
                setUserLang={setUserLang}
                userLang={userLang}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              <InputAdornment
                position="end"
                style={{ marginLeft: isSmallDown ? "0px" : "8px" }}
              >
                {isRecording && (
                  <>
                    <IconButton
                      style={{
                        color: "#fff",
                        marginLeft: isSmallDown ? "0px" : "8px",
                        padding: isSmallDown ? "5px" : "8px",
                      }}
                      onClick={() => {
                        setIsDeleted(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24 "
                        width={isSmallDown ? "20" : "24"}
                        height={isSmallDown ? "20" : "24"}
                        fill="#c2a07ebd"
                      >
                        <path
                          d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                          fill="#c2a07ebd"
                        />
                      </svg>
                    </IconButton>

                    <div
                      style={{
                        backgroundColor: "#c2a07ebd",
                        borderRadius: "15px",
                        marginRight: "4%",
                        width: "100%",
                        padding: "4px",
                        display: "flex",
                      }}
                    >
                      <LiveAudioVisualizer
                        width={isSmallDown ? "96%" : "100%"}
                        height={30}
                        mediaRecorder={mediaRecorder ? mediaRecorder : null}
                        barColor="#212121"
                        barWidth={2}
                      />
                    </div>
                  </>
                )}

                <VoiceInput
                  onTranscript={handleVoiceInput}
                  setIsRecording={setIsRecording}
                  isRecording={isRecording}
                  setMediaRecorder={setMediaRecorder}
                  mediaRecorder={mediaRecorder}
                  isDeleted={isDeleted}
                  setIsDeleted={setIsDeleted}
                />

                <IconButton
                  style={{
                    backgroundColor:
                      input.length !== 0 && !isRecording && !isLoading
                        ? "#c2a07ebd"
                        : "rgb(231 206 175)",
                    border: "1px",
                    cursor:
                      input.length !== 0 && !isRecording && !isLoading
                        ? "pointer"
                        : "not-allowed",
                    padding: isSmallDown ? "5px" : "8px",
                  }}
                  onMouseDown={() => {
                    if (input.length !== 0) {
                      handleSend(input);
                      setInput("");
                    }
                  }}
                  disabled={isRecording || input.length === 0 || isLoading}
                >
                  <svg
                    width={isSmallDown ? "20" : "25"}
                    height={isSmallDown ? "20" : "25"}
                    viewBox="5 5 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon-2xl"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z"
                      fill="#2f2f2f"
                    ></path>
                  </svg>
                </IconButton>
              </InputAdornment>
            </>
          ),
          sx: {
            "& .MuiOutlinedInput-root": {
              width: "100%",
              borderRadius: "26px !important",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent !important",
              borderRadius: "26px !important",
            },
            "& .MuiOutlinedInput-input": {
              color: "#212121",
              fontSize: isMediumDown ? "14px" : "1rem",
              lineHeight: isMediumDown ? "16px" : "1.5rem",
              textOverflow: "ellipsis",
            },
            "& input:-webkit-autofill": {
              borderRadius: "26px !important",
              border: "1px solid transparent !important",
            },
          },
          inputProps: {
            sx: {
              "&::placeholder": {
                color: "#212121",
                fontSize: isMediumDown ? "14px" : "1rem",
                lineHeight: isMediumDown ? "16px" : "1.5rem",
              },
            },
          },
        }}
        sx={{
          borderRadius: "26px",
          backgroundColor: "hsl(0deg 0% 100% / 90%)",
          border: "1.5px solid hsl(0deg 0% 100% / 90%)",
          width: isMediumDown ? "100%" : "75%",
          
        }}
      />
    </Box>
  );
}

export default MessageInput;
