import { useEffect, useState, useCallback } from "react";
import { Box, Container, useMediaQuery } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";

import MessageContainerAds from "./container/messageContainerAds";
import MessageInputAds from "./input/messageInputAds";
import AdsReportHeader from "./header/headerAds";
import BottomNav from "./BottomNav";
const ChatAds = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const isLandscape = useMediaQuery("(orientation: landscape)");
  const [feedback, setFeedback] = useState(0);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [files, setFiles] = useState([]);
  const [messages, setMessages] = useState(() => {
    const savedMessages = sessionStorage.getItem("chatMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  const [sessionId, setSessionId] = useState("");
  const [headerlab, setheaderLab] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userLang, setUserLang] = useState("English");
  const [initialMessage, setInitialMessage] = useState(
    location.state?.initialMessage || ""
  );
  const handleLogout = () => {
    // sessionStorage.removeItem("access_token");
    sessionStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    if (location.state?.initialMessage) {
      setInitialMessage(location.state.initialMessage);
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (initialMessage) {
      handleSendMessage(initialMessage);
      setInitialMessage("");
    }
  }, [initialMessage]);
  useEffect(() => {
    sessionStorage.setItem("chatMessages", JSON.stringify(messages));
    sessionStorage.setItem("chatSessionId", sessionId);
  }, [messages, sessionId]);

  const fetchAPI = useCallback(async (url, options) => {
    try {
      const response = await fetch(url, options);
      if (!response.ok) throw new Error(await response.text());
      return await response.json();
    } catch (error) {
      console.error("API Error:", error.message);
      throw error;
    }
  }, []);
  //translation text
  const translateText = useCallback(
    async (text, sourceLang, targetLang) => {
      if (sourceLang === targetLang) return text;

      const url = process.env.REACT_APP_TRANSLATE_API;
      const token = sessionStorage.getItem("access_token");

      return fetchAPI(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ text, sourceLang, targetLang }),
      }).then((data) => data.translatedText);
    },
    [fetchAPI]
  );

  const handleSendMessage = useCallback(
    async (message, selectedFile) => {

      const senderMessage = {
        sender: "user",
        senderID: "test1",
        senderType: "admin",
        text: message || "",
        file: selectedFile ? selectedFile?.name : null,
      };
      setFiles((prev) => [...prev, selectedFile]);
      setMessages((prev) => [...prev, senderMessage]);
      setIsLoading(true);

      try {
        const translatedQuery =
          userLang !== "English"
            ? await translateText(message, userLang, "English")
            : message;

        const formData = new FormData();
        formData.append("query", translatedQuery);
        formData.append("sessionId", sessionId || "");
        if (selectedFile) formData.append("file", selectedFile);

        const url = process.env.REACT_APP_CHAT_API;
        const token = sessionStorage.getItem("access_token");

        const data = await fetchAPI(url, {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
        });

        if (!data || !data.generated_text) {
          throw new Error("Error: Invalid response data");
        }

        const chatResponse = data.generated_text;
        const userPrompt = data.prompt ?? "";
        const updatedSenderMessage = { ...senderMessage, userPrompt };
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          updatedMessages[updatedMessages.length - 1] = updatedSenderMessage;
          return updatedMessages;
        });

        const botMessage = {
          sender: "bot",
          id: uuidv4(),
          text: chatResponse || "",
        };

        setMessages((prevMessages) => [...prevMessages, botMessage]);

        const { sqlData, ...botMessageForSave } = botMessage;
        await saveChatSession(
          updatedSenderMessage,
          botMessageForSave,
          feedback
        );
      } catch (error) {
        console.error("Error in handleSendMessage:", error);
        setMessages((prev) => [
          ...prev,
          { sender: "bot", text: "Error generating response" },
        ]);
      } finally {
        setIsLoading(false);
      }
    },
    [feedback, sessionId, translateText, userLang, fetchAPI]
  );

  const saveChatSession = useCallback(
    async (senderMessage, botMessage, feedback) => {
      const url = process.env.REACT_APP_SAVE_CHAT_API;
      const token = sessionStorage.getItem("access_token");

      return fetchAPI(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: sessionId,
          userMessage: senderMessage,
          botResponse: botMessage,
          responseFeedback: feedback,
        }),
      }).then((data) => setSessionId(data.id || sessionId));
    },
    [fetchAPI, sessionId]
  );

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: isMediumDown ? "0px 8px 56px" : "3%",
        flexGrow: isMediumDown && 1,
        minHeight: isMediumDown ? "none" : "100vh",
        overflowY: isMediumDown ? "auto" : "",
        // backgroundColor: "#f1f3f6",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: isMediumDown
            ? "0px"
            : messages.length !== 0
            ? "220px"
            : "0",
          display: "flex",
          flexDirection: "column",
          marginTop: isMediumDown ? (isLandscape ? "15%" : "18%") : "0%",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: isMediumDown ? "" : "140px",
        }}
      >
        <AdsReportHeader
          setheaderLab={setheaderLab}
          handleLogout={handleLogout}
        />
        <MessageContainerAds
          messages={messages}
          sessionId={sessionId}
          feedback={feedback}
          onFeedbackChange={setFeedback}
          handleSend={handleSendMessage}
          loading={isLoading}
          setFiles={setFiles} // Pass the setter for other use cases
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "sticky",
          bottom: "0",
          padding: isMediumDown ? "0" : 2,
          backdropFilter: isMediumDown ? "blur(2px)" : "",
          width: "100%",
        }}
      >
        <MessageInputAds
          messages={messages}
          handleSend={handleSendMessage}
          userLang={userLang}
          setUserLang={setUserLang}
          isLoading={isLoading}
          setIsInputFocused={setIsInputFocused}
        />
      </Box>
      {isMediumDown && <BottomNav handleLogout={handleLogout} />}
    </Container>
  );
};

export default ChatAds;
