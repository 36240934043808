import React from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";

const BottomNav = ({ handleLogout }) => {
  const storedUser = sessionStorage.getItem("userDetails");
  const user = JSON.parse(storedUser);
  return (
    <BottomNavigation
      showLabels
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        background: "rgb(40 116 240)",
        zIndex: 1300,
      }}
    >
      <BottomNavigationAction
        label={<div style={{ color: "white" }}>Logout</div>}
        icon={
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "rgb(40 116 240)",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {user?.name[0]?.toUpperCase()}
          </div>
        }
        onClick={() => {
          handleLogout();
        }}
      />
    </BottomNavigation>
  );
};

export default BottomNav;
