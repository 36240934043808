import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  useMediaQuery,
  Typography,
  CircularProgress,
} from "@mui/material";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VoiceInputAds from "./voiceInputAds";
import ResponseTranslationAds from "./responseTransalationAds";
function MessageInputAds({
  messages,
  handleSend,
  userLang,
  setUserLang,
  isLoading,
  setIsInputFocused,
}) {
  const [input, setInput] = useState("");
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleVoiceInput = (transcript) => {
    handleSend(transcript);
  };
  useEffect(() => {
    console.log("selectedFile", selectedFile);
  }, [selectedFile]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const fileInputRef = React.createRef();

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    setSelectedFile(null); // Only clear when explicitly removed
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend(input, selectedFile);
      setInput("");
      setSelectedFile(null);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: isSmallDown ? "8px" : "10px",
        width: "100%",
      }}
    >
      <TextField
        placeholder={
          isRecording
            ? "Recording..."
            : selectedFile
            ? ""
            : "Enter your message and attach file"
        }
        onChange={(e) => setInput(e.target.value)}
        value={input}
        onKeyDown={handleKeyDown}
        InputProps={{
          onFocus: (e) => {
            e.preventDefault();
            setIsInputFocused(true);
          },
          onBlur: (e) => {
            e.preventDefault();
            setIsInputFocused(false);
          },
          startAdornment: (
            <InputAdornment position="start">
              <ResponseTranslationAds
                setUserLang={setUserLang}
                userLang={userLang}
              />
              <IconButton
                style={{
                  backgroundColor: "rgb(40, 116, 240)",
                  padding: isSmallDown ? "5px" : "6px",
                  marginRight: "8px",
                  marginLeft: "5px",
                }}
                onClick={handleFileSelect}
              >
                <AttachFileIcon
                  style={{
                    color: "#fcfcfc",
                    fontSize: isSmallDown ? "20px" : "24px",
                  }}
                />
              </IconButton>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              {selectedFile && !isMediumDown && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: selectedFile.type.startsWith("image")
                      ? "flex-start"
                      : "center",
                    justifyContent: "space-between",
                    maxWidth: "auto",
                    maxHeight: "300px",
                    position: "relative",
                    border: "1px solid rgb(225 235 253)",
                    borderRadius: "5px",
                    backgroundColor: "white",
                    padding: selectedFile.type.startsWith("image") ? "" : "2%",
                    top: selectedFile.type.startsWith("image") ? "10px" : "",
                  }}
                >
                  {selectedFile.type.startsWith("image") ? (
                    <>
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Selected"
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "5px",
                        }}
                      />
                      <CancelSharpIcon
                        onClick={handleRemoveFile}
                        size="small"
                        sx={{
                          position: "absolute",
                          top: "4px",
                          right: "4px",
                          color: "rgb(40 116 240)",
                          cursor: "pointer",
                          backgroundColor: "white",
                          borderRadius: "50%",
                          padding: "2px",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="body2"
                        sx={{
                          maxWidth: "calc(100% - 24px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {selectedFile.name}
                      </Typography>
                      <CancelSharpIcon
                        onClick={handleRemoveFile}
                        size="small"
                        sx={{
                          marginLeft: "8px",
                          padding: "4px",
                          color: "rgb(40 116 240)",
                          cursor: "pointer",
                        }}
                      />
                    </>
                  )}
                </Box>
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <>
              <InputAdornment
                position="end"
                style={{ marginLeft: isSmallDown ? "0px" : "8px" }}
              >
                {isRecording && (
                  <>
                    <IconButton
                      style={{
                        color: "rgb(40, 116, 240)",
                        marginLeft: isSmallDown ? "0px" : "8px",
                        padding: isSmallDown ? "5px" : "8px",
                      }}
                      onClick={() => {
                        setIsDeleted(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={isSmallDown ? "20" : "24"}
                        height={isSmallDown ? "20" : "24"}
                        fill="rgb(40, 116, 240)"
                      >
                        <path
                          d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                          fill="rgb(40, 116, 240)"
                        />
                      </svg>
                    </IconButton>

                    <div
                      style={{
                        backgroundColor: "rgb(40, 116, 240)",
                        borderRadius: "15px",
                        marginRight: "4%",
                        width: "100%",
                        padding: "4px",
                        display: "flex",
                      }}
                    >
                      <LiveAudioVisualizer
                        width={isSmallDown ? "96%" : "100%"}
                        height={30}
                        mediaRecorder={mediaRecorder ? mediaRecorder : null}
                        barColor="#212121"
                        barWidth={2}
                      />
                    </div>
                  </>
                )}

                <VoiceInputAds
                  onTranscript={handleVoiceInput}
                  setIsRecording={setIsRecording}
                  isRecording={isRecording}
                  setMediaRecorder={setMediaRecorder}
                  mediaRecorder={mediaRecorder}
                  isDeleted={isDeleted}
                  setIsDeleted={setIsDeleted}
                />

                <IconButton
                  style={{
                    backgroundColor:
                      input.length !== 0 && !isRecording && !isLoading
                        ? "rgb(40, 116, 240)"
                        : "rgb(40, 116, 240)",
                    border: "1px",
                    cursor:
                      input.length !== 0 && !isRecording && !isLoading
                        ? "pointer"
                        : "not-allowed",
                    padding: isSmallDown ? "5px" : "8px",
                  }}
                  onMouseDown={() => {
                      handleSend(input, selectedFile);
                      setInput("");
                      setSelectedFile(null);
                  }}
                  disabled={isRecording || isLoading}
                >
                  <svg
                    width={isSmallDown ? "20" : "25"}
                    height={isSmallDown ? "20" : "25"}
                    viewBox="5 5 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon-2xl"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z"
                      fill="#fcfcfc"
                    ></path>
                  </svg>
                </IconButton>
              </InputAdornment>
            </>
          ),
          sx: {
            "& .MuiOutlinedInput-root": {
              width: "100%",
              borderRadius: "26px !important",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent !important",
              borderRadius: "26px !important",
            },
            "& .MuiOutlinedInput-input": {
              color: "#212121",
              fontSize: isMediumDown ? "14px" : "1rem",
              lineHeight: isMediumDown ? "16px" : "1.5rem",
              textOverflow: "ellipsis",
              paddingLeft:"7px"
            },
            "& input:-webkit-autofill": {
              borderRadius: "26px !important",
              border: "1px solid transparent !important",
            },
          },
          inputProps: {
            sx: {
              "&::placeholder": {
                color: "#212121",
                fontSize: isMediumDown ? "14px" : "1rem",
                lineHeight: isMediumDown ? "16px" : "1.5rem",
              },
            },
          },
        }}
        sx={{
          borderRadius: "26px",
          backgroundColor: "rgb(40 116 240 / 14%)",
          // border: "1.5px solid rgb(40, 116, 240)",
          width: isMediumDown ? "100%" : "75%",
          height: selectedFile?.type.startsWith("image") ? "85px" : "60px",
        }}
      />
      {selectedFile && isMediumDown && (
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxHeight: "300px",
            border: "1px solid rgb(225 235 253)",
            borderRadius: "5px",
            backgroundColor: "white",
            padding: "8px",
          }}
        >
          {selectedFile.type.startsWith("image") ? (
            <img
              src={URL.createObjectURL(selectedFile)}
              alt="Selected"
              style={{
                height: "50px",
                width: "50px",
                borderRadius: "5px",
              }}
            />
          ) : (
            <Typography
              variant="body2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "calc(100% - 24px)",
              }}
            >
              {selectedFile.name}
            </Typography>
          )}
          {uploading && (
            <CircularProgress size={16} sx={{ marginLeft: "8px" }} />
          )}
          <CancelSharpIcon
            onClick={handleRemoveFile}
            size="small"
            sx={{
              marginLeft: "8px",
              cursor: "pointer",
              color: "rgb(40 116 240)",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default MessageInputAds;
