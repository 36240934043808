import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import Noviro from "../../labReportComponents/assets/Noviro unit white.png";
const AdsReportHeader = ({ setheaderLab, handleLogout }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!sessionStorage.getItem("access_token")
  );

  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const isLandscape = useMediaQuery("(orientation: landscape)");

  const titleContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    width: window.location.pathname === "/login" ? "95%" : "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: isLandscape ? "1% 3%" : "3% 3% 5%",
    position: "fixed",
    top: 0,
    left: 0,
    background: "#2874f0",
    zIndex: 1000,
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
  };

  const NoviroStyle = {
    width: isMediumDown ? "75px" : "150px",
    height: isMediumDown ? "25px" : "50px",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: isMediumDown ? "7px" : "21px",
        }}
      >
        <img
          alt="flipkartLogo"
          src={require("../../assets/flipkartLogo.png")}
          width={isMediumDown ? "30px" : "45px"}
          height={isMediumDown ? "30px" : "45px"}
        />
        <div
          style={{
            fontSize: isMediumDown ? "20px" : "30px",
            color: "#fcfdff",
            fontWeight: 600,
          }}
        >
          AD INSIGHTS AGENT
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
        <img src={Noviro} alt="Noviro Logo" style={NoviroStyle} />
        {isMediumDown || window.location.pathname === "/login" ? (
          <></>
        ) : (
          <button
            style={{
              padding: "4%",
              backgroundColor: "white",
              border: "0px",
              fontSize: "15px",
              borderRadius: "6px",
              color: "rgb(40, 116, 240)",
            }}
            onClick={() => handleLogout()}
          >
            Logout
          </button>
        )}
      </div>
    </div>
  );
};

export default AdsReportHeader;
