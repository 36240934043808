import React from "react";
import { Box, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Document, Page, pdfjs } from "react-pdf";

// Configure the PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const FileDisplay = ({ files }) => {
  const handleFileClick = (file) => {
    if (file instanceof File) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    } else {
      console.error("Invalid file object:", file);
    }
  };

  return files.map((file, index) => (
    <Box
      key={index}
      sx={{
        marginTop: "10px",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        borderRadius: "4px",
        padding: "8px",
        wordBreak: "break-word",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100px",
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RemoveRedEyeIcon
          onClick={() => handleFileClick(file)}
          sx={{
            position: "absolute",
            color: "rgba(0, 0, 0, 0.6)",
            cursor: "pointer",
          }}
        />
        {file instanceof File && file.type?.startsWith("image/") ? (
          <img
            src={URL.createObjectURL(file)}
            alt="Uploaded file"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "4px",
            }}
          />
        ) : file instanceof File && file.type === "application/pdf" ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Document
              file={file} // Pass the file directly
              onLoadError={(error) =>
                console.error("Error loading PDF:", error.message)
              }
              loading={<Typography>Loading PDF...</Typography>}
              error={
                <Typography>Unable to load PDF. Please try again.</Typography>
              }
            >
              <Page pageNumber={1} width={90} />
            </Document>
          </Box>
        ) : (
          <Typography>
            <a
              href={file instanceof File ? URL.createObjectURL(file) : "#"}
              download={file.name || "file"}
              style={{
                color: "blue",
                textDecoration: "underline",
              }}
            >
              {file.name || "Download file"}
            </a>
          </Typography>
        )}
      </Box>
    </Box>
  ));
};

export default FileDisplay;
